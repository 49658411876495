import { bankAccountRules, ibanCurrencies } from "consts";
import { IFeeIncludeOptions, IQuote } from "types";
import {QUOTE} from "../hooks/use-get-quote/use-get-quote";

export const getCrypto = (
    amount: number,
    quote: QUOTE,
    fee: number,
    type: IFeeIncludeOptions
) => {
  if (amount === 0) return '0.00000'

  if (quote.feeAmount > 0) {
    if (type === IFeeIncludeOptions.EXCLUDE) {
      return Math.max(+amount / +quote.feeAmount, 0).toFixed(5)
    } else if (type === IFeeIncludeOptions.INCLUDE) {
      return Math.max((+amount - +fee) / +quote.feeAmount, 0).toFixed(5)
    } else if (type === IFeeIncludeOptions.MERCHANT) {
      return Math.max(+amount / +quote.feeAmount, 0).toFixed(5)
    } else {
      return '0.00000'
    }
  } else {
    if (
        type === IFeeIncludeOptions.EXCLUDE ||
        type === IFeeIncludeOptions.MERCHANT
    ) {
      const _amountNoFee = Math.max(+amount) * +quote.rate
      return _amountNoFee.toFixed(5)
    } else if (type === IFeeIncludeOptions.INCLUDE) {
      const _amountFee = Math.max(+amount - +fee, 0) * +quote.rate
      return _amountFee.toFixed(5)
    } else {
      return '0.00000'
    }
  }
}

export const getFiat = (
    amount: number,
    quote: QUOTE,
    fee: number,
    type: IFeeIncludeOptions
) => {
  if (amount === 0) return '0.00'

  if (quote.feeAmount > 0) {
    if (type === IFeeIncludeOptions.EXCLUDE) {
      return Math.max(+amount * +quote.feeAmount, 0).toFixed(2)
    } else if (type === IFeeIncludeOptions.INCLUDE) {
      return Math.max(+amount * +quote.feeAmount - +fee, 0).toFixed(2)
    } else if (type === IFeeIncludeOptions.MERCHANT) {
      return Math.max(+amount * +quote.feeAmount, 0).toFixed(2)
    } else {
      return '0.00'
    }
  } else {
    if (type === IFeeIncludeOptions.EXCLUDE) {
      return Math.max(+amount, 0).toFixed(2)
    } else if (type === IFeeIncludeOptions.INCLUDE) {
      return Math.max(+amount - +fee, 0).toFixed(2)
    } else if (type === IFeeIncludeOptions.MERCHANT) {
      return Math.max(+amount, 0).toFixed(2)
    } else {
      return '0.00'
    }
  }
}


export const ellipsisAddress = (address: string) => {
  return `${address.slice(0, 6)}...${address.slice(
    address.length - 4,
    address.length
  )}`;
};

export const getBankInputFields = (currency: string) => {
  if (ibanCurrencies.includes(currency)) {
    return bankAccountRules.IBAN_COUNTRY;
  }
  if (Object.keys(bankAccountRules).includes(currency))
    return bankAccountRules[currency];
  return bankAccountRules.OTHER;
};

export const validateBankInputFields = (accountDetail: any, currency: string) => {
  return getBankInputFields(currency).every(({ name }: { name: string }) => !!accountDetail[name]);
};
