import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { CardInfoPage, ConfirmOrderPage, PaymentSuccessPage } from "pages";
import { AppActions, RootState } from "store";
import { PlatformContext } from "contexts";
import {PATHS, RAMPING, saasUserCorporationId, TransactionTypeForFee} from "consts";
import { IUser, OrderType } from "types";
import "./App.css";
import { VeriffAfterAuth } from "pages/veriff";
import { RoundDown2Digits } from "utils";
import EnterWalletAddress from "pages/EnterWalletAddress";
import BillingAddress from "pages/BillingAddress";
import AddBank from "pages/AddBank";
import PendingIcon from "assets/icons/PendingIcon";
import { validateBankInputFields } from "utils/supports";
import { Button } from "components";
import CryptoDeposit from "pages/CryptoDeposit";
import {useGetQuote} from "./hooks/use-get-quote/use-get-quote";
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';

function App() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const location = useLocation();

  const {
    isLogIn,
    ssoPayload,
    user,
    setLogIn,
    saveSsoPayload,
    saveToken,
    saveUser,
    resetCookies,
  } = useContext(PlatformContext);

  const [isCardValid, setIsCardValid] = useState(true);
  const { isLoading } = useSelector((root: RootState) => root.auth);
  const [errorMessage, setErrorMessage] = useState('');
  const params = new URLSearchParams(window.location.search);

  const login = (data: any, feeAmount?: number) => {
    const {
      merchant_id: merchantId,
      sso_customer_id: ssoCustomerId,
      sso_hash: ssoHash,
      email_address: emailAddress,
      customer_first_name: firstName,
      customer_surname: lastName,
      dob,
      ip_address: ipAddress,
      order_amount: orderAmount,
    } = data;

    let amount;
    amount = RoundDown2Digits(orderAmount);

    dispatch(
      AppActions.auth.ssoAuth({
        merchantId,
        ssoCustomerId,
        ssoHash,
        emailAddress,
        firstName,
        lastName,
        dob,
        saasUserCorporationId: 1,
        ipAddress,
        orderAmount: amount,
        next: (token: string, user: IUser) => {
          saveUser(user);
          saveToken(token);
          setLogIn(true);
          window.parent.postMessage(
            {
              type: "logged_in_success",
              token,
            },
            "*"
          );
        },
        kycRequired: (user: IUser) => {
          saveUser(user);
          navigate(PATHS.KYC);
          window.parent.postMessage(
            {
              type: "kyc_required",
            },
            "*"
          );
        },
        error: (error: any) => {
          resetCookies();

          window.parent.postMessage(
            {
              type: "logged_in_failure",
              errorCode: 400,
            },
            "*"
          );
        },
      })
    );
  }



  const handleSsoLogin = async (data: any) => {
    saveSsoPayload({
      ...data,
      includeFee: +data.include,
    });
    login(data);
  }

  const messageListener = (event: MessageEvent<any>) => {
    const { data } = event;

    console.log("Message received from the parent: " + JSON.stringify(data));

    const { type } = data;

    if (!type) {
      return;
    }

    if (type === "sso_login") {
      handleSsoLogin(data);
    }

    if (type === "cko-msg") {
      if (data.event === "cardValidationChanged") {
        setIsCardValid(data.data.isValid);
      } else if (data.event === "paymentMethodChanged") {
        setIsCardValid(data.data.isPaymentMethodAccepted);
        if (!data.data.isPaymentMethodAccepted && data.data.paymentMethod) {
          dispatch(
            AppActions.order.createErrorOrderStatus({
              message:
                "We don't support this card type. Please use another card or contact our support team.",
            })
          );
        }
      }
    }
  };

  useEffect(() => {
    window.parent.postMessage({ type: "page_loaded" }, "*");
    window.addEventListener("message", messageListener);

    const ssoData: any = {};
    params.forEach((value, key) => {
      ssoData[key] = value;
    });
    console.log('ssoData: ', ssoData);
    if (ssoData.order_type !== undefined) {
      handleSsoLogin(ssoData);
    }

    return () => {
      window.removeEventListener("message", messageListener);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!ssoPayload) return;

    const {
      order_type: orderType,
    } = ssoPayload;
    if (+orderType === OrderType.ON_RAMP || +orderType === OrderType.NFT_ON_RAMP) {
      if (
        location.pathname.includes(PATHS.CARD_INFO) ||
        location.pathname.includes(PATHS.PAYMENT_SUCCESS)
      ) {
      } else if (isLogIn) {
        if (!location.pathname.includes(PATHS.CARD_INFO))
          navigate(PATHS.CONFIRM_ORDER);
      }
    }
  }, [isLogIn, ssoPayload, location.pathname, navigate]);

  useEffect(() => {
    if (!ssoPayload || !user) return;

    const {
      order_type: orderType,
      wallet_address: walletAddress,
      currency,
      sort_code: sortCode,
      account_number: accountNumber,
      routing_number: routingNumber,
      bsb_code: bsbCode,
      address_line1: streetLine1,
      city,
      country,
      zip: postcode,
      bank_country: bankCountry,
      ...rest
    } = ssoPayload;
    const accountDetail = {
      sortCode,
      accountNumber,
      routingNumber,
      bsbCode,
      ...rest,
    };

    if (isLogIn && +orderType === OrderType.OFF_RAMP) {
      if (!walletAddress) {
        navigate(PATHS.WALLET_ADDRESS);
        return;
      }
      const isValid = validateBankInputFields(accountDetail, currency);
      if (!isValid) {
        navigate(PATHS.ADD_BANK);
        return;
      }
      if (!streetLine1 || !city || !country || !postcode) {
        navigate(PATHS.BILLING_ADDRESS);
        return;
      }

      const {
        id: userId,
        firstName,
        lastName,
      } = user;

      dispatch(AppActions.order.getCounterpartyId({
        next: (data: any) => {
          const { httpsStatus, accountId, message } = data;
          if (!!accountId || httpsStatus === 422) {
            navigate(PATHS.CONFIRM_ORDER);
          }
          else
            setErrorMessage(message);
        },
        error: (error: any) => {
          console.log('error: ', error);
          setErrorMessage('It has not been possible to place your Order at this time. Please try again or contact the Support team');
        },
        userId: userId,
        issuerCountry: bankCountry,
        currency,
        individualName: {
          firstName,
          lastName,
        },
        ...{
          ...accountDetail,
          accountNo: accountNumber,
        },
        address: {
          streetLine1,
          city,
          country,
          postcode,
        }
      }));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogIn, ssoPayload, user]);

  useEffect(() => {
    dispatch(
      AppActions.crypto.getCryptos({
        saasUserCorporationId: saasUserCorporationId,
      })
    );
    dispatch(
      AppActions.currency.getCurrencies({
        saasUserCorporationId: saasUserCorporationId,
      })
    );
    dispatch(
      AppActions.country.getCountry({
        saasUserCorporationId: saasUserCorporationId,
      })
    )
  }, [dispatch]);

  const onCancelContinue = () => {
    window.parent.postMessage(
      {
        type: "order_cancelled",
      },
      "*"
    );
    setErrorMessage("");
  };

  return (
    <ErrorBoundary
      fallback={
        <div className="fixed inset-0 flex items-center justify-center bg-gray-100 bg-opacity-90">
          <div className="bg-white p-8 rounded-xl shadow-lg max-w-md w-full mx-4">
            <div className="text-center">
              <h2 className="text-2xl font-semibold text-red-600 mb-4">
                Application Error
              </h2>
              <p className="text-gray-600 mb-6">
                We're sorry, but something went wrong. Please try again or contact support if the problem persists.
              </p>
              <button
                onClick={() => window.location.reload()}
                className="bg-red-600 text-white px-6 py-2 rounded-lg hover:bg-red-700 transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
              >
                Reload Application
              </button>
            </div>
          </div>
        </div>
      }
    >
      <div className="app flex flex-col h-screen">
        {isLoading && (
          <div className="fixed w-full h-full flex justify-center items-center">
            <div className={`w-36 h-36 z-20 animate-spin-slow`}>
              <PendingIcon className="fill-font-secondary w-36 h-36" />
            </div>
          </div>
        )}
        {errorMessage && (
          <div className="fixed w-full h-full flex items-center justify-center">
            <div className="fixed -z-50 w-full h-full bg-black opacity-60"></div>
            <div className="z-100 flex flex-col items-center bg-white p-8 mx-4 border border-1 rounded-xl">
              <p className="text-md font-medium text-font-primary text-center">
                {errorMessage}
              </p>
              <Button className="h-12 py-1 px-4 mt-4" onClick={onCancelContinue}>
                Cancel
              </Button>
            </div>
          </div>
        )}
        <Routes>
          <Route path={PATHS.CONFIRM_ORDER} element={<ConfirmOrderPage />} />
          <Route
            path={PATHS.CARD_INFO + "/*"}
            element={<CardInfoPage isCardValid={isCardValid} />}
          />
          <Route
            path={PATHS.WALLET_ADDRESS + "/*"}
            element={<EnterWalletAddress crypto={ssoPayload?.crypto_currency as string} />}
          />
          <Route
            path={PATHS.ADD_BANK}
            element={<AddBank />}
          />
          <Route
            path={PATHS.BILLING_ADDRESS}
            element={<BillingAddress />}
          />
          <Route
            path={PATHS.PAYMENT_SUCCESS + "/*"}
            element={<PaymentSuccessPage />}
          />
          <Route
            path={PATHS.CRYPTO_DEPOSIT}
            element={<CryptoDeposit />}
          />
          <Route path={PATHS.KYC} element={<VeriffAfterAuth />} />
          <Route path="*" element={<div></div>} />
        </Routes>
      </div>
    </ErrorBoundary>
  );
}

export default App;
