import React, { createContext, useState } from "react";
import { ISsoPayload, IUser } from "types";

type IPlatformContext = {
  ssoPayload: ISsoPayload | null;
  isLogIn: boolean;
  user: IUser | null;
  getLogIn: () => boolean;
  getUser: () => IUser | null;
  setLogIn: (login: boolean) => void;
  saveToken: (token: string) => void;
  saveUser: (user: IUser) => void;
  saveSsoPayload: (data: ISsoPayload) => void;
  resetCookies: () => void;
};

export const PlatformContext = createContext<IPlatformContext>({
  ssoPayload: null,
  isLogIn: false,
  user: null,
  getLogIn: () => false,
  getUser: () => null,
  setLogIn: () => {},
  saveToken: () => {},
  saveUser: () => {},
  saveSsoPayload: () => {},
  resetCookies: () => {},
});

type PlatformContextProviderProps = {
  children: React.ReactNode;
};

export const PlatformContextProvider: React.FC<
  PlatformContextProviderProps
> = ({ children }) => {
  const [isLogIn, setLogIn] = useState<boolean>(false);
  const [ssoPayload, setSsoPayload] = useState<ISsoPayload | null>(null);
  const [user, setUser] = useState<IUser | null>(null);

  const saveToken = (token: string) => {
    localStorage.setItem("token", token);
  };

  const saveUser = (_user: IUser) => {
    setUser(_user);
    localStorage.setItem("user", JSON.stringify(_user));
  };

  const resetCookies = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
  };

  const saveSsoPayload = (data: ISsoPayload) => {
    setSsoPayload(data);
  };

  const getLogIn = () => {
    const token = localStorage.getItem("token");
    if (token) {
      setLogIn(true);
      return true;
    }
    return false;
  };

  const getUser = () => {
    const userData = localStorage.getItem("user");
    return userData ? JSON.parse(userData) : null;
  };

  return (
    <PlatformContext.Provider
      value={{
        ssoPayload,
        isLogIn,
        user,
        getLogIn,
        getUser,
        setLogIn,
        saveToken,
        saveUser,
        resetCookies,
        saveSsoPayload,
      }}
    >
      {children}
    </PlatformContext.Provider>
  );
};
