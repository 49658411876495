//node_modules
//slices
import { AppActions } from "store";
//sagas
import { ssoAuthSaga, ssoKycSaga } from "./auth.saga";
import {
  createSsoOrderSaga,
  createPaymentCheckoutSaga,
  getPaymentCheckoutDetailSaga,
  getCounterpartyIdSaga,
} from "./order.saga";

import { all, takeLatest } from "redux-saga/effects";
import { getCryptosSaga } from "./crypto.saga";
import { getCurrenciesSaga } from "./currency.saga";
import { getFeeSaga } from "./fee.saga";
import { getCountriesSaga } from "./country.saga";

//sagas
function* rootSaga() {
  //auth
  yield all([takeLatest(AppActions.auth.ssoAuth.type, ssoAuthSaga)]);
  yield all([takeLatest(AppActions.auth.ssoKyc.type, ssoKycSaga)]);

  //order
  yield all([
    takeLatest(AppActions.order.createSsoOrder.type, createSsoOrderSaga),
  ]);

  yield all([takeLatest(AppActions.crypto.getCryptos.type, getCryptosSaga)]);

  yield all([
    takeLatest(AppActions.currency.getCurrencies.type, getCurrenciesSaga),
  ]);

  yield all([takeLatest(AppActions.fee.getFee.type, getFeeSaga)]);

  yield all([
    takeLatest(
      AppActions.order.createPaymentCheckout.type,
      createPaymentCheckoutSaga
    ),
  ]);

  yield all([
    takeLatest(
      AppActions.order.getPaymentDetail.type,
      getPaymentCheckoutDetailSaga
    ),
  ]);

  
  yield all([
    takeLatest(
      AppActions.country.getCountry.type,
      getCountriesSaga
    ),
  ]);

  yield all([
    takeLatest(
      AppActions.order.getCounterpartyId.type,
      getCounterpartyIdSaga
    ),
  ]);
}

export default rootSaga;
