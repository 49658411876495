import { useEffect, useState, useCallback } from 'react'
import { useIdleTimer } from 'react-idle-timer'

export const useCountdownRefetch = (
	initialTime: number,
	refetch: () => void
) => {
	const [updateIn, setUpdateIn] = useState(initialTime)
	const [state, setState] = useState<string>('Active')
	const onIdle = () => {
		setState('Idle')
	}

	const onActive = () => {
		setState('Active')
	}

	useIdleTimer({
		onIdle,
		onActive,
		timeout: 30_000,
		throttle: 500
	})

	useEffect(() => {
		const interval = setInterval(() => {
			setUpdateIn((prev) => (prev > 0 ? prev - 1 : initialTime))
		}, 1000)

		if (updateIn === 0 && state === 'Active') {
			refetch()
		}

		return () => {
			clearInterval(interval)
		}
	}, [updateIn, initialTime, state, refetch])

	return {
		updateIn,
		reset: useCallback(() => setUpdateIn(initialTime), [initialTime])
	}
}
