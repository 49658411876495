import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import QRCode from "react-qr-code";

import CopyIcon from "assets/icons/CopyIcon";
import CheckIcon from "assets/icons/CheckIcon";
import { RootState } from "store";
import { ellipsisAddress } from "utils/supports";
import { PlatformContext } from "contexts";
import { cryptoImages } from "assets";

const CryptoDeposit: React.FC = () => {
  const [copied, setCopied] = useState<boolean>(false);
  const { ssoPayload } = useContext(PlatformContext);
  const { order } = useSelector((state: RootState) => state.order);

  const onClickCopy = () => {
    if (!order?.depositWalletAddress) return;
    navigator.clipboard.writeText(order.depositWalletAddress)
      .then(() => {
        setCopied(true);
      })
      .catch((error) => {
        setCopied(false);
      });
  }

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    }
  }, [copied]);

  const onCancelContinue = () => {
    window.parent.postMessage(
      {
        type: "order_completed",
      },
      "*"
    );
  };

  useEffect(() => {
    console.log('order: ', order);
  }, [order]);

  if (!order || !order.depositWalletAddress) return null;

  return (
    <>
      <div className="flex justify-between p-4">
        <span className="text-base font-medium text-font-primary">
          Deposit Instruction
        </span>
        <button
          className="text-base font-medium text-font-primary"
          onClick={onCancelContinue}
        >
          Close
        </button>
      </div>      {
        !!order &&
        <div className="p-4 mt-4">
          <h1 className="text-2xl font-bold text-font-primary mb-4">Deposit Instruction</h1>
          <p className="text-sm font-medium text-font-primary mb-8">Send {ssoPayload?.crypto_currency} to the following deposit wallet address. Please check the order details carefully before sending, as we cannot retrieve {ssoPayload?.crypto_currency} sent to the wrong address.</p>
          <p className="text-sm font-medium text-font-primary mb-2">Once the {ssoPayload?.crypto_currency} has been sent to the deposit wallet address, then this window can be closed</p>
          <span className="text-base font-bold text-font-primary">Please send <span className="text-base font-medium text-primary-light">{order.cryptoAmount} {ssoPayload?.crypto_currency}</span> to the following address:</span>

          <div className="flex justify-between items-center bg-feedback-information-tint p-2 rounded-xl mt-2">
            <div className="flex items-center">
              <div className="w-5 h-5 rounded-full bg-center bg-cover mr-2" style={{ backgroundImage: `url(${cryptoImages[order.cryptoId]})` }} />
              <span className="text-base font-medium text-font-primary">{ellipsisAddress(order.depositWalletAddress as string)}</span>
            </div>
            {
              copied ?
                <CheckIcon className="duration-100" /> :
                <CopyIcon className="focus:w-5 focus:h-5 duration-100" onClick={onClickCopy} />
            }
          </div>

          <div className="flex justify-center mt-8">
            <QRCode className="w-48 h-48" value={order.depositWalletAddress as string} />
          </div>
          <p className="text-sm font-medium text-font-primary mt-8">Once we’ve received your {ssoPayload?.crypto_currency}, we will send {ssoPayload?.currency} to your bank account. This can take a few hours.</p>
          <p className="text-sm font-medium text-font-primary mt-8">If you have any questions, <a href={`mailto:${ssoPayload?.support_email || 'customer-support@wynpay.io'}`} className="text-primary">we’re here to help.</a></p>
        </div>
      }
    </>
  );
};

export default CryptoDeposit;