import { useQuery } from '@tanstack/react-query'
import {TransactionTypeForFee} from "../../consts";

export type QUOTE = {
	from: string
	to: string
	fromAmount: number
	toAmount: number
	feeAmount: number
	rate: number
	feeCurrency: string
	error?: boolean
}

export type UseGetQuoteProps = {
	transactionType: TransactionTypeForFee | undefined
	from: string | undefined
	to: string  | undefined
	fromAmount: number | undefined
}

export const useGetQuote = ({
	transactionType,
	from,
	to,
	fromAmount
}: UseGetQuoteProps) => {
	return useQuery({
		queryKey: [`get-quotes`],
		queryFn: async (): Promise<QUOTE | null> => {

			const url = process.env.REACT_APP_API_PUBLIC_API +
				'/quote/get-quote-without-fee?from=' + from +
				'&to=' + to +
				'&fromAmount=' + fromAmount +
				'&transactionType=' + transactionType

			const response = await fetch(url,{
				headers:{
					'Content-Type': 'application/json',
					'x-api-key': process.env.REACT_APP_X_API_KEY || '',
					Authorization: 'Bearer ' + localStorage.getItem('token'),
				}
			})
			if (!response.ok) {
				return  {
					from: from || '',
					to: to || '',
					fromAmount: 0,
					toAmount: 0,
					feeAmount: 0,
					rate: 0,
					feeCurrency: '',
					error:true
				}
			}
			return await response.json()
		},
		refetchOnWindowFocus: true,
		retryOnMount: true,
		enabled: !!from && !!to && !!fromAmount && !!transactionType
	})
}
