import {cancelInCircleSvg, infoSvg} from "assets";
import {RAMPING, TransactionTypeForFee} from "consts";
import {useEffect, useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {AppActions, RootState} from "store";
import {IFeeIncludeOptions} from "types";
import {getCrypto, getFiat} from "utils/supports";
import {QUOTE} from "../hooks/use-get-quote/use-get-quote";
import {useCountdownRefetch} from "../hooks/use-countdown/use-countdown";

interface IProps {
  type: RAMPING;
  crypto: string;
  currency: string;
  amount: number;
  includeFee: IFeeIncludeOptions;
  nftName?: string;
  quote:QUOTE | undefined | null;
  refetch:()=>void
}

export const BuyCryptoSummary: React.FC<IProps> = (props) => {
  const { type, amount, crypto,quote, currency, includeFee, nftName, refetch } = props;
  const { currencies } = useSelector((state: RootState) => state.currency);
  const { fee } = useSelector((state: RootState) => state.fee);
  const [isMarketLink, setMarketLink] = useState<boolean>(false);

  const { updateIn } = useCountdownRefetch(10, refetch)

  const feeAmount = useMemo(() => {
    if(quote) {
      let feeAmount = type === RAMPING.ON ? amount : +getFiat(amount, quote, fee.fee, includeFee);
      if (includeFee === IFeeIncludeOptions.EXCLUDE) {
        return feeAmount + +fee.fee;
      }
      return feeAmount;
    }
  }, [type, amount, quote, includeFee, fee.fee]);


  const _currency = useMemo(() => {
    return currencies.find((el) => el.code === currency);
  }, [currency, currencies]);

  useEffect(() => {
    if (fee.fee > 0) {
      setMarketLink(true);
    } else {
      setMarketLink(false);
    }
  }, [fee.fee]);

  const isFeeDisplay = useMemo(() => {
    return !(includeFee === IFeeIncludeOptions.MERCHANT && type === RAMPING.OFF);
  }, [includeFee, type]);

  return (
    <>

      <div className="flex justify-between items-end mb-2">
        <label className="text-base font-medium text-font-primary">
          Summary
        </label>
        <label className="text-xs font-medium text-gray-400">
          Quote updates in {updateIn}s
        </label>
      </div>
      {!!quote?.error && (
        <div
          className="flex items-center bg-feedback-warning rounded-xl px-4 py-2 ml-4 mr-4 mb-4 mt-4"
          onClick={() => {}}
        >
          <img src={cancelInCircleSvg} />
          <p className="text-sm font-medium text-font-secondary ml-2">
            Get Quote Service is not available and can’t place orders. Pls
            contact support
          </p>
        </div>
      )}
      {quote && feeAmount && (
      <div className="bg-feedback-information-tint divide-y p-4 rounded-xl">
        <span className="text-base font-medium text-font-primary mb-2">
          You get{" "}
          <span className="font-bold">
            {!!nftName
              ? nftName
              : {
                  [RAMPING.ON]: (
                    <>
                      {getCrypto(amount, quote, fee.fee, includeFee)} {crypto}
                    </>
                  ),
                  [RAMPING.OFF]: (
                    <>
                      ~{_currency && _currency.symbol}
                      {getFiat(amount, quote, fee.fee, includeFee)}
                    </>
                  ),
                }[type]}
          </span>{" "}
          for{" "}
          <span className="font-bold">
            {!!nftName ? (
              <>
                {_currency && _currency.symbol}
                {feeAmount.toFixed(2)}
              </>
            ) : (
              {
                [RAMPING.ON]: (
                  <>
                    {_currency && _currency.symbol}
                    {feeAmount.toFixed(2)}
                  </>
                ),
                [RAMPING.OFF]: (
                  <>
                    {amount.toFixed(5)} {crypto}
                  </>
                ),
              }[type]
            )}
          </span>
        </span>
        <div className="mt-4">
          {!nftName && (
            <div className="flex justify-between items-end mt-4">
              <div>
                <span className="text-sm font-medium text-font-primary mr-1">
                  {(
                    (+feeAmount -
                      (includeFee === IFeeIncludeOptions.MERCHANT
                        ? 0.0
                        : fee.fee)) /
                    (+quote.feeAmount > 0 ? +quote.feeAmount : 1)
                  ).toFixed(5)}
                </span>
                <span className="text-sm font-medium text-gray-500">
                  @ {_currency && _currency.symbol}
                  {(+quote.feeAmount).toFixed(2)}
                </span>
              </div>
              <span className="text-sm font-medium text-font-primary">
                {_currency && _currency.symbol}
                {(
                  feeAmount -
                  (includeFee === IFeeIncludeOptions.MERCHANT ? 0.0 : fee.fee)
                ).toFixed(2)}
              </span>
            </div>
          )}

          {
            isFeeDisplay && (
              <>
                <div className="flex justify-between items-end mt-3">
                  <div className="flex">
                    <span className="text-sm font-medium text-font-primary mr-1">
                      Processing fee
                    </span>
                  </div>
                  <div className="flex items-center">
                    <span className="text-xs font-medium text-gray-500 mr-1">
                      as low as
                    </span>
                    <span className="text-sm font-medium text-font-primary">
                      {_currency && _currency.symbol}
                      {includeFee === IFeeIncludeOptions.MERCHANT ? "0.00" : fee.fee}
                    </span>
                  </div>
                </div>
                {isMarketLink && (
                  <div className="flex mt-1">
                    <img src={infoSvg} />
                    <div className="">
                      <p className="text-xs font-medium text-gray-500">
                        To be able to compare the quoted rate against the market rate
                        please follow this{" "}
                        <a
                          className="text-primary"
                          href="https://www.coinbase.com/explore"
                          target="_blink"
                        >
                          link
                        </a>
                      </p>
                    </div>
                  </div>
                )}
              </>
            )
          }
        </div>
      </div>
      )}
    </>

  );
};

export default BuyCryptoSummary;
